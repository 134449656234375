@import '../../../assets/scss/colors.scss';

.page {
  margin: 40px 32px 0 32px;
  overflow-y: scroll;
  height: 100%;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .headline {
    font-weight: 700;
    font-size: 20px;
    line-height: 27.28px;
  }
}
.head2 {
  display: flex;
  align-items: center;
  .headline2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 27.28px;
    margin-left: 12px;
  }
}

.lh27 {
  // line-height: 27.28px;
  margin-left: 16px;
  margin-top: 1.5px;
}
.table-count {
  margin-top: 20px;
  margin-bottom: 24px;
}
.skuBtn {
  background: #cee4ff;
  color: #0067e2;
  text-align: center;
  padding: 10px 16px;
  height: 40px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  border-width: 0;
  margin-right: 24px;
  cursor: pointer;
}

.button-switch {
  display: flex;
  margin: 16px 0;
  cursor: pointer;

  background-color: var(--fill-light-gray-bg);
  width: fit-content;
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  .btn-selected {
    background: #cee4ff;
    color: #0067e2;
    text-align: center;
    padding: 9px 30px;
    // height: 40px;
    border-radius: 8px;
    font-weight: bold;
    // font-size: 16px;
    border-width: 0;
  }

  .btn-unselected {
    padding: 9px 30px;
    color: var(--fill-grey-dark2);
  }
}

.form-row {
  .form-column {
    margin-bottom: 32px;
    max-width: 100%;
    flex: 0 0 50%;
  }
  .form-button {
    display: flex;
    justify-content: space-around;
    margin-top: 2.5rem;
  }
}

.name {
  font-size: 10.5px;
  padding-left: 6px;
}
.cross {
  text-align: center;
  padding: 10px 20px 0px;
}

.flex {
  display: flex;
}

.pointer {
  cursor: pointer;
}

.edit {
  color: var(--fill-primary-blue);
  font-size: small;
  font-weight: bold;
  // width: 6.5rem;
  text-align: center;
  border: 1px solid var(--fill-light-blue);
  border-radius: 6px;
  padding: 8px 16px;
  margin-right: 24px;
  cursor: pointer;
}
.editBtn {
  display: flex;
  color: var(--fill-primary-blue);
  background-color: var(--fill-blue-light3);
  border: 1px solid var(--fill-light-blue);
  height: 32px;
  width: 32px;
  font-size: small;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-right: 24px;
  cursor: pointer;
}
.saveDisabled {
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 8px;
  padding: 12px;
  background: var(--fill-blue-300);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  cursor: pointer;
}
.save {
  height: 40px;
  border: none;
  border-radius: 8px;
  padding: 12px;
  background: var(--fill-primary-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  cursor: pointer;
}
.inventoryTable {
  thead {
    tr {
      th {
        background: var(--fill-light-gray-bg) !important;
        font-weight: 700;
      }
    }
  }
  margin-bottom: 100px;
}
.clear {
  width: 183px;
  height: 40px !important;
  font-weight: 700 !important;
  color: var(--fill-primary-blue) !important;
  border: none !important;
}
.apply {
  width: 183px;
  height: 40px !important;
  background-color: var(--fill-primary-blue) !important;
  border-radius: 8px !important;
  color: white !important;
}
.uploadTxt {
  font-size: 12px;
}
.downloadContainer {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}
.downloadAnchor {
  text-decoration: underline;
  color: var(--fill-primary-blue);
  width: '40%';
}
.viewStyles {
  color: var(--fill-primary-blue);
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}
.suppliers-modal {
  .ant-modal-content {
    border-radius: 16px !important;
  }
  .ant-modal-header {
    border-bottom-color: white;
    padding-top: 24px;
    border-radius: 16px 16px 0px 0px;
  }
  .ant-modal-close-x {
    padding-top: 6px;
  }
  .ant-modal-body {
    padding-top: 18px;
    padding-bottom: 12px;
  }
  .ant-modal-footer {
    border-top-color: white;
    padding-bottom: 26px;
  }
  .ant-modal-title {
    font-size: 20px;
    font-weight: 700;
  }
}
.dot {
  background: var(--fill-grey-dark3);
  border-radius: 50%;
  height: 4px;
  margin: 0 8px;
  width: 4px;
}
.specification-column {
  display: flex;
  align-items: center;
}

.pointer {
  cursor: pointer;
}
.pop-box{
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  min-width: 10rem;

  .popValue{
    font-weight: 600;
    padding-right: 4px;
  }
}

.seperator{
  border-bottom: dashed;
  border-color: gray;
  border-width: 1px;
  margin: 8px 0px;
}